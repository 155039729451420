.teamBio-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .teamBio-modal {
    position: relative;
    width: 45vw;
    max-height: 75vh;
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    &-button {
      position: absolute;
      top: 20px;
      right: 20px;
      padding-right: 10px;
      display: inline-block;
    }
  }
}

.teamBio-container {
  width: 100%;
  padding: 16px;
  min-height: 200px;
  background: linear-gradient(to bottom right, #fafafa, #ddd);

  .teamBio {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    &-heading {
      width: fit-content;
      font-size: $heading_two;
      font-family: $robotoBold;
      text-transform: capitalize;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(to bottom right, $primarycolor, #039aa5) 1;
    }
    &-body {
      color: $black;
      line-height: 1.5;
      text-align: justify;
      font-size: $text;
      font-family: $robotoMedium;
    }
  }
}
