.noDataUI-container {
  .noDataUI {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #999;
    min-height: 150px;

    &-icon {
      font-size: 40px;
    }

    &-message {
      font-size: 24px;
      font-family: $robotoMedium;
    }
  }
}
