// FOOTER STYLES
%footer-title {
  font-family: $robotoBold;
  font-size: $heading_two;
  color: $black;
  margin-bottom: 20px;
}

.footer-container {
  width: 100%;
  margin-top: 60px;
  padding-top: 30px;
  position: relative;

  .footer {
    width: $default_width;
    margin: 0px auto;

    &-links {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include responsive-for(md) {
        flex-direction: column;
      }

      &-block {
        margin-top: 10px;
        flex: 2;

        &:nth-child(2) {
          flex: 1.5;
          margin-left: 140px;
          margin-right: 140px;

          @include responsive-for(md) {
            margin-left: 0px;
            margin-right: 0px;
          }
        }

        &.first {
          width: 30%;

          @include responsive-for(md) {
            margin-bottom: 20px;
            width: 100%;
          }

          .footer {
            &-title {
              @extend %footer-title;
            }

            &-desc {
              color: $black;
              font-size: $text;
              line-height: 24px;
              margin-bottom: 10px;
            }

            &-icons {
              display: flex;

              &-image {
                position: relative;
                cursor: pointer;
                margin-right: 16px;
                color: $black;
              }
            }
          }
        }

        &-title {
          @extend %footer-title;
        }

        &-links {
          list-style: none;
          width: 100%;

          @include responsive-for(md) {
            margin-bottom: 20px;
          }

          .links-item {
            display: flex;
            font-family: $robotoRegular;
            font-size: $text;
            cursor: pointer;
            user-select: none;
            color: $black;
            transition: color 0.4s;
            margin-bottom: 12px;

            &-icon {
              position: relative;
              top: 1px;
            }

            span {
              margin-right: 10px;
              font-size: $text;
              line-height: 24px;
            }
          }

          input {
            padding: 14px;
            margin: 0 0 10px 0;
            width: 100%;
            border: 1px solid #d3d3d3;
            border-radius: 5px;
            outline: none;

            @include responsive-for(md) {
              width: 85vw;
            }

            &:focus {
              border: 1px solid $primarycolor;
            }
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .footerinfo-container {
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid $grey;

    .footerinfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: $default_width;
      margin: 0px auto;

      @include responsive-for(md) {
        flex-direction: column;
      }

      &-left {
        font-family: $robotoRegular;
        font-size: $text;
        color: $light_black;
        line-height: 24px;

        @include responsive-for(md) {
          text-align: center;
        }

        span {
          color: $primarycolor;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $robotoRegular;
        font-size: $text;
        color: $light_black;

        @include responsive-for(md) {
          margin-top: 10px;
        }

        span {
          padding-right: 10px;
        }

        img {
          width: 70px;
          margin-top: -5px;
        }
      }
    }
  }
}
