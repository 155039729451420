.Introduction-Information-wrapper {
  width: $default_width;
  margin: 60px auto 0 auto;
  .research {
    margin-bottom: 15px;
    .title {
      font-family: $robotoMedium;
      font-size: 20px;
    }
    .paragraph {
      padding: 20px 0;
      p {
        font-family: $robotoRegular;
        line-height: 24px;
        text-align: justify;

        @include responsive-for(md) {
          line-height: 22px;
        }
      }
      &-cards {
        display: grid;

        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }
}
