// COLLABORATOR STYLES

.articlecard-container {
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;

  .articlecard {
    &-image {
      height: 300px;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &-info {
      padding: 12px;
      &-header {
        border-bottom: 1px solid $light_grey;
        padding-bottom: 8px;
        margin-bottom: 12px;
        height: 88px;
        .title {
          font-size: $text;
          font-family: $robotoMedium;
          color: $black;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .desc {
          padding-top: 5px;
          font-size: $text;
          font-family: $robotoMedium;
          color: $light_black;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $light_black;

        .date {
          display: flex;
          align-content: center;
          font-size: $text;
          color: $light_black;

          .icon {
            font-size: $text;
            margin-top: -3px;
            margin-right: 4px;
          }
        }

        .seeall {
          cursor: pointer;
          display: flex;
          align-content: center;
          font-size: $text;
          font-family: $robotoMedium;
          color: $primarycolor;

          .icon {
            margin-top: 1px;
            margin-left: 2px;
          }
        }

        &-seemore {
          color: $primarycolor;
          display: flex;
          justify-content: space-between;
          text-decoration: none;

          @include responsive-for(md) {
            display: none;
          }

          &-mobile {
            display: none;
            @include responsive-for(md) {
              display: flex;
              color: $primarycolor;
              font-size: $heading_one;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
