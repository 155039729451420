.seemore-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .seemore {
    font-family: $robotoMedium;
    margin-right: 4px;
    color: $primarycolor;
    &-icon {
      position: relative;
      top: 2px;
      color: $primarycolor;
    }
  }

  &:hover {
    .seemore {
      color: #05738e;

      &-icon {
        color: #05738e;
      }
    }
  }

  &:active {
    .seemore {
      color: $primarycolor_dark;

      &-icon {
        color: $primarycolor_dark;
      }
    }
  }
}



.seemoreres-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .seemoreres {
    font-family: $robotoMedium;
    margin-right: 4px;
    color: $primarycolor;
    @include responsive-for(md) {
      display: none;
    }
    &-icon {
      position: relative;
      top: 2px;
      color: $primarycolor;
    }
  }

  &:hover {
    .seemoreres {
      color: #05738e;

      &-icon {
        color: #05738e;
      }
    }
  }

  &:active {
    .seemoreres {
      color: $primarycolor_dark;

      &-icon {
        color: $primarycolor_dark;
      }
    }
  }
}
