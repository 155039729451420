%headerdropdown {
  color: $black;

  h3 {
    // margin-bottom: 10px;
  }

  p {
    line-height: 24px;
    color: $light_black;
  }
}

.headerdropdown {
  position: absolute;
  width: 48vw;
  background: $white;
  top: 100%;
  border-radius: 4px;
  box-shadow: 0px 6px 46px rgba(0, 0, 0, 0.16);
  display: flex;

  &-title {
    border: none;
    color: $white;
    background: transparent;
    font-size: $text;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      font-family: $robotoMedium;
    }

    &-down {
      padding-left: 4px;
      position: relative;
      top: 2px;
      transition: transform 0.2s;
    }
  }

  &-links {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    &-item {
      display: flex;
      align-items: center;
      transition: background 0.2s;
      margin-left: 0 !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;

      &:not(:last-child) {
        border-bottom: 1px solid $light_grey;
      }

      .links-item-icon {
        color: $black;
        background: $light_grey;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .links-item-text {
        flex: 1;
        @extend %headerdropdown;
        width: 100%;
        margin-left: 14px;
      }

      &:hover {
        background: $extralight_grey;
      }

      &:active {
        background: darken($extralight_grey, 4%);
      }
    }
  }

  &-info {
    flex: 0.6;

    flex: 1;
    padding: 20px;
    border-left: 1px solid $light_grey;

    &-image {
      width: 100%;
      background: $light_grey;
      border-radius: 4px;
      height: 160px;
      margin-bottom: 20px;
      background-position: center;
      background-size: cover;

      background-repeat: no-repeat;
    }

    &-text {
      @extend %headerdropdown;

      p {
        text-wrap: wrap;
      }
    }
  }
}
