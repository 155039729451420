.notfound-container{
    height: 50vh;
    .notfound{
        position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
        display: flex;
        flex-direction: row;
        &-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-big{
                font-size: $heading_one;
                font-family: $robotoBold;
            }
            &-small{
                font-size: $text;
                font-family: $robotoMedium;
                color: grey;
            }
        }
    }
}