@font-face {
  src: url("../../assets/fonts/Roboto-Bold.ttf");
  font-family: RobotoBold;
}

@font-face {
  src: url("../../assets/fonts/Roboto-Medium.ttf");
  font-family: RobotoMedium;
}

@font-face {
  src: url("../../assets/fonts/Roboto-Regular.ttf");
  font-family: RobotoRegular;
}

@import "./1-variables", "./2-mixins", "./3-generics";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: RobotoRegular, "Montserrat", "Raleway", "Tahoma";
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $light_grey;
}
