// BUTTON STYLES

.button {
  outline: none;
  border: none;
  width: 100%;
  padding: 14px 40px;
  border-radius: 4px;
  font-family: $robotoMedium;
  font-size: $text;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background 0.2s;

  &.primary {
    background: rgba(255, 255, 255, 0);
    border: 2px solid $white;
    color: $white;

    &:hover {
      background: $white;
      color: $primarycolor;
    }

    &:active {
      background: $grey;
    }
  }

  &.secondary {
    background: $primarycolor;
    border: 2px solid $primarycolor;
    color: $white;

    &:hover {
      background: #05738e;
    }

    &:active {
      background: $primarycolor_dark;
    }
  }

  &.gototop {
    background-image: linear-gradient(to bottom right, $primarycolor, #039aa5);
    border: 2px solid $white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;

    .goto-title {
      color: $white;
      margin-right: 5px;

      @include responsive-for(sm) {
        display: none;
      }
    }

    .goto-icon {
      color: $white;
      position: relative;
      top: 2px;

      @include responsive-for(sm) {
        margin: 0px 2px;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &.vacancy {
    background: $white;
    border: 2px solid $primarycolor;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    cursor: pointer;

    .title {
      color: $primarycolor;
      margin-right: 5px;

      @include responsive-for(sm) {
        display: none;
      }
    }

    .icon {
      color: $primarycolor;
      position: relative;
      top: 2px;

      @include responsive-for(sm) {
        margin: 0px 2px;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &.close {
    background: $white;
    border: 2px solid $light_black;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    cursor: pointer;

    .title {
      color: $light_black;
      margin-right: 5px;

      @include responsive-for(sm) {
        display: none;
      }
    }

    .icon {
      color: $light_black;
      position: relative;
      top: 2px;

      @include responsive-for(sm) {
        margin: 0px 2px;
      }
    }

    &:hover {
      .icon {
        color: rgb(255, 69, 60);
      }
      transform: scale(1.05);
    }
  }
}
