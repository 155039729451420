// LANGUGAGE SELECT
.selectlanguage {
  width: 100%;
  background: $white;
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid $primarycolor;
  border-radius: 4px;
  color: $black;
  cursor: pointer;
  user-select: none;

  &-flag {
    width: 14px;
    height: 14px;
    background: $grey;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;

    img {
      width: 14px;
    }
  }

  &-alias {
    font-family: $robotoMedium;
    margin-left: 4px;
    font-size: $small;
  }

  &-down {
    margin-left: 4px;
    transition: transform 0.2s;
  }
}

.languagedropdown {
  position: absolute;
  background: $white;
  top: 100%;
  left: 0px;
  border-radius: 4px;
  box-shadow: 0px 6px 46px rgba(0, 0, 0, 0.16);
  display: flex;
  z-index: 10;

  &-title {
    border: none;
    font-family: $robotoMedium;
    color: $black;
    background: transparent;
    font-size: $text;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 2px;
  }
}
