.introduction-container {
  width: 100%;

  @include responsive-for(md) {
    width: 100%;
  }

  .introduction {
    // width: 100%;
    height: 100%;
    background: $white;
    border-radius: 4px;
    padding: 20px;
    display: grid;
    grid-template-columns: 3fr 4fr;
    column-gap: 20px;
    justify-content: center;

    @include responsive-for(md) {
      grid-template-columns: 1fr;
    }

    &-image-container {
      height: 100%;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px;
      // width: 100%;
      // height: 100%;
      // border-radius: 4px;
      // overflow: hidden;

      // .image {
      //   display: flex;
      //   flex: 1;
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      // }
    }
    &-desc {
      @include responsive-for(md) {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
      &-title {
        font-size: $heading_two;
        font-family: $robotoMedium;
        color: $black;
        border-bottom: 1px solid $light_grey;
        padding-bottom: 6px;
        margin-bottom: 16px;
      }
      &-content {
        font-size: $text;
        font-family: $robotoRegular;

        display: -moz-box;
        display: -webkit-box;
        line-clamp: 14;
        -webkit-line-clamp: 14;
        -moz-line-clamp: 14;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        overflow: hidden;

        @include responsive-for(md) {
          font-size: $text;
        }
      }
      &-footer {
        margin-top: 20px;
        cursor: pointer;


        @include responsive-for(md) {
          position: relative;
          margin-top: 6px;
        }

      }
    }
  }
}
