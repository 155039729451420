.research-carousel-container {
  .wrapper {
    .slide-image {
      height: 300px;
      width: 100%;
      border-radius: 4px;
    }
  }

  .slick-dots li {
    margin: 0 -1px;
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    display: block;
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style: none;
    text-align: center;
  }
  .slick-dots li.slick-active {
    .dots {
      background-color: $white;
      color: transparent;
      opacity: 1;
      width: 20px;
      border-radius: 4px;
      transform: translateX(-28%);
    }
  }

  .dots {
    background-color: $grey;
    color: transparent;
    opacity: 1;
    width: 9px;
    height: 6px;
    border-radius: 4px;
    transition: width 0.2s;
  }
}
