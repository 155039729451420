.carousel-container {
  background: $primarycolor;
  height: 100vh;
  position: relative;

  .carousel-text {
    width: $default_width;
    margin-left: 7.5%;
    top: 34vh;
    position: absolute;
    z-index: 10;

    @include responsive-for(md) {
      top: 25vh;
    }

    .main {
      h1,
      h2 {
        font-family: $robotoBold;
        color: $white;
        font-size: 60px;

        @include responsive-for(md) {
          font-size: 36px;
        }

        @include responsive-for(sm) {
          font-size: 26px;
        }
      }
    }

    .secondary {
      width: 70%;
      margin-top: 60px;

      @include responsive-for(md) {
        width: 100%;
        margin-top: 20px;
      }

      @include responsive-for(sm) {
        width: 100%;
        margin-top: 20px;
      }

      p {
        font-family: $robotoRegular;
        color: $white;
        line-height: 30px;
      }
    }

    &-button {
      margin-top: 40px;
      width: 180px;
    }
  }

  .image-slider-container {
    display: flex;
    position: relative;

    .image-slider {
      flex: 1 0 100%;
      width: 100%;
      height: 100vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .activeIndicator-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 10;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
}
