// NEWS UPDATE STYLES

.newsupdate-container {
  margin-top: 60px;
  width: $default_width;
  margin-left: auto;
  margin-right: auto;

  .newsupdate {
    &-layout {
      margin: 0px auto;

      .slick-track {
        margin-left: 0;
      }
    }
  }
}
