// ALL THE GENERAL STYLES WILL BE HERE

// @override SLICK DOTS
.slick-slide,
.slick-slide * {
  outline: none !important;
}

.slick-list {
  margin: 0 -10px;
}
.slick-slide > div {
  padding: 0 10px;
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  display: block;
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;

  li {
    margin: 0;

    button:before {
      background-color: $grey;
      color: transparent;
      opacity: 1;
      width: 9px;
      height: 6px;
      border-radius: 4px;
      transition: width 0.2s;
    }

    &.slick-active {
      button:before {
        background-color: $primarycolor;
        color: transparent;
        opacity: 1;
        width: 22px;
        border-radius: 4px;
        transform: translateX(-30%);
      }
    }
  }
}

// NO RESULTS
.no-results {
  color: $light_black;
}

.line-height {
  &-normal {
    line-height: 24px;
  }

  &-large {
    line-height: 30px;
  }

  &-extralarge {
    line-height: 39px;
  }
}

// MARGINS
.m {
  // MARGIN-LEFT
  &l-1 {
    margin-left: 2px;
  }
  &l-2 {
    margin-left: 4px;
  }
  &l-3 {
    margin-left: 8px;
  }
  &l-4 {
    margin-left: 10px;
  }
  &l-5 {
    margin-left: 20px;
  }

  // MARGIN-RIGHT
  &r-1 {
    margin-right: 2px;
  }
  &r-2 {
    margin-right: 4px;
  }
  &r-3 {
    margin-right: 8px;
  }
  &r-4 {
    margin-right: 10px;
  }
  &r-5 {
    margin-right: 20px;
  }

  // MARGIN-TOP
  &t-1 {
    margin-top: 2px;
  }
  &t-2 {
    margin-top: 4px;
  }
  &t-3 {
    margin-top: 8px;
  }
  &t-4 {
    margin-top: 10px;
  }
  &t-5 {
    margin-top: 20px;
  }

  // MARGIN-BOTTOM
  &b-1 {
    margin-bottom: 2px;
  }
  &b-2 {
    margin-bottom: 4px;
  }
  &b-3 {
    margin-bottom: 8px;
  }
  &b-4 {
    margin-bottom: 10px;
  }
  &b-5 {
    margin-bottom: 20px;
  }
}

// PADDINGS
.p {
  // PADDING-LEFT
  &l-1 {
    padding-left: 2px;
  }
  &l-2 {
    padding-left: 4px;
  }
  &l-3 {
    padding-left: 8px;
  }
  &l-4 {
    padding-left: 10px;
  }
  &l-5 {
    padding-left: 20px;
  }

  // PADDING-RIGHT
  &r-1 {
    padding-right: 2px;
  }
  &r-2 {
    padding-right: 4px;
  }
  &r-3 {
    padding-right: 8px;
  }
  &r-4 {
    padding-right: 10px;
  }
  &r-5 {
    padding-right: 20px;
  }

  // PADDING-TOP
  &t-1 {
    padding-top: 2px;
  }
  &t-2 {
    padding-top: 4px;
  }
  &t-3 {
    padding-top: 8px;
  }
  &t-4 {
    padding-top: 10px;
  }
  &t-5 {
    padding-top: 20px;
  }

  // PADDING-BOTTOM
  &b-1 {
    padding-bottom: 2px;
  }
  &b-2 {
    padding-bottom: 4px;
  }
  &b-3 {
    padding-bottom: 8px;
  }
  &b-4 {
    padding-bottom: 10px;
  }
  &b-5 {
    padding-bottom: 20px;
  }
}
