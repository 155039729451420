// COLLABORATOR STYLES

.collaboratorcard-container {
  border-radius: 4px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .collaboratorcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 10px;
    height: 130px;

    .logo {
      height: 100px;

      img {
        height: 100px;
      }
    }

    .text {
      margin-top: 10px;
      font-size: $text;
      font-family: $robotoRegular;
      color: $black;
      text-align: center;
    }
  }
}
