.vacancy-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .vacancy-modal {
    width: $default_width;
    height: 86vh;
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    background-image: url("../../../assets/images/Vacancy\ Illustration.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-y: auto;

    &-button {
      position: sticky;
      top: 25px;
      float: right;
      padding-right: 10px;
      display: inline-block;
    }
  }
}
