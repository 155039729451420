.contact-form-wrapper {
  // padding: 60px 0 0 0;
  .contact-form-container {
    padding-right: 10px;
    label {
      display: block;
      padding: 0 0 4px 0;
    }
    input {
      padding: 14px;
      margin: 0 0 16px 0;
      width: 100%;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      outline: none;

      &:focus {
        border: 1px solid $primarycolor;
      }
    }

    .contact-title {
      font-family: $robotoBold;
      padding: 0 0 60px 0;
    }
    .contact-form-form {
      .contact-details {
        display: flex;
        justify-content: space-between;
        .contact-name {
          width: 50%;
          padding-right: 10px;
        }
        .contact-phone {
          width: 50%;
          padding-left: 10px;
        }
      }
      .contact-email {
      }
      .contact-subject {
      }
      .contact-message {
        textarea {
          resize: none;
          width: 100%;
          padding: 10px;
          border: 1px solid #d3d3d3;
          border-radius: 5px;
          outline: none;
        }
      }
      .contact-button {
        margin-top: 20px;
        width: 40%;
        @include responsive-for(md) {
          width: 100%;
        }
      }
    }
  }
}
