// COLLABORATERS STYLES

.collaborators-container {
  margin-top: 80px;
  width: $default_width;
  margin-left: auto;
  margin-right: auto;

  .collaborators {
    &-layout {
      margin: 0px auto;
    }
  }
}
