.fund {
  font-size: $text;
  height: 100%;
  font-family: $robotoMedium;
  color: $black;
  border-radius: 4px;
  overflow: hidden;
  background-color: $white;
  cursor: pointer;

  &-image {
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
  }
  &-title {
    background-color: $white;
    padding: 10px 20px;
    font-size: $text;
    font-family: $robotoMedium;
    color: $black;
  }
}
