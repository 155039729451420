.vacancy-container {
  .vacancy {
    width: 600px;
    padding: 30px;

    &-title {
      color: $primarycolor;
      font-family: $robotoBold;
      font-size: $heading_one;
    }
    &-section {
      margin-top: 20px;

      &-title {
        font-family: $robotoBold;
      }

      &-body {
        margin-top: 10px;
        font-family: $robotoRegular;
        font-size: $small;
        line-height: 25px;
        color: $black;
        ul {
          padding-left: 20px;
          //   list-style: none;
          li {
            font-family: $robotoRegular;
          }
        }
      }
    }
  }
}
