.IntroCard-wrapper {
  margin-top: 20vh;

  .container {
    padding-top: 4vh;
    .card {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      // align-items: center;
      background-color: $white;
      width: $default_width;
      padding: 4vh;
      box-shadow: 0px 6px 46px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      transition: 0.3s;

      @include responsive-for(md) {
        flex-direction: column;
      }
      .left {
        width: 50%;
        padding: 0 30px 0 0;
        @include responsive-for(md) {
          width: 100%;
          padding: 0 0 20px 0;
        }
        .title {
          font-family: $robotoBold;
          color: $primarycolor;
          font-size: 40px;
          @include responsive-for(md) {
            font-size: 30px;
          }
        }
        .paragraph {
          p {
            font-family: $robotoRegular;
            padding: 10px 0;
            line-height: 24px;
            text-align: justify;

            @include responsive-for(md) {
              line-height: 22px;
            }

            .hyperlink {
              color: $primarycolor;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      .right {
        width: 50%;
        height: 100%;
        @include responsive-for(md) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 48vh;
          border-radius: 4px;
          @include responsive-for(md) {
            height: 50%;
          }
        }
      }
    }
  }
}
