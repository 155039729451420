.fundedby-container {
  width: $default_width;
  margin: 100px auto;

  @include responsive-for(md) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .fundedby {
    width: 100%;
    height: 200px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    column-gap: 20px;
    align-self: center;

    @include responsive-for(md) {
      height: 80px;
    }

    &-NIHR {
      height: 100%;
      background-position: left top;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-uk {
      height: 100%;
      background-position: right top;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
