.subtitle {
  margin-bottom: 10px;
  &-title {
    font-family: $robotoMedium;
    font-size: $text;

    span {
      font-family: $robotoRegular;
    }
  }
}
