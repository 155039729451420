// TITLE STYELS

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;

  .title {
    font-size: $heading_two;
    font-family: $robotoBold;
    color: $black;
  }

  .right {
    color: $primarycolor;
  }

  &.border {
    border-bottom: 1px solid $light_grey;
  }
}
