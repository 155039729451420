// GALLERY STYLES
@mixin image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.gallery-container {
  margin-top: 80px;

  .gallery {
    &-title {
      width: $default_width;
      margin-left: auto;
      margin-right: auto;
    }

    &-layout {
      margin-left: 7.5%;
      margin-right: 7.5%;
      display: grid;
      grid-template-columns: 7fr 5fr;
      column-gap: 20px;
      row-gap: 20px;

      @include responsive-for(sm) {
        grid-template-columns: 1fr;
        row-gap: 20px;
      }

      &-left {
        display: grid;
        grid-template-columns: 7fr 5fr;
        column-gap: 20px;

        .left {
          height: 600px;
          width: 100%;
          display: grid;
          grid-template-rows: 2fr 1fr;
          row-gap: 20px;

          &-top {
            @include image;
          }

          &-down {
            @include image;
          }
        }

        .middle {
          height: 600px;
          width: 100%;
          display: grid;
          grid-template-rows: 1fr 2fr;
          row-gap: 20px;

          &-top {
            width: 100%;
            @include image;
          }

          &-down {
            @include image;
          }
        }
      }

      .right {
        height: 600px;
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 20px;

        &-top {
          @include image;
        }

        &-down {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;

          &-left {
            @include image;
          }

          &-right {
            @include image;
          }
        }
      }

      .galleryimg {
        cursor: pointer;

        img {
          transition: transform 0.4s;
        }

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
