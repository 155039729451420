//NEWS STYLES
.news-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @include responsive-for(lg) {
    grid-template-columns: repeat( auto-fill, minmax(280px, 1fr) );
    }

  // @include responsive-for(md) {
  //   grid-template-columns: 1fr 1fr;
  // }

  // @include responsive-for(sm) {
  //   grid-template-columns: 1fr;
  // }
}
