.map-container {
  margin-top: 60px;

  .map {
    box-shadow: 0px 6px 46px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    overflow: hidden;
    background: $white;
    height: 50vh;
    width: 100%;
    &-google {
      > div {
        width: 0% !important;

        @include responsive-for(md) {
          height: 50vh;
        }
      }

      &-mobile {
        display: none;
        > div {
          width: 0% !important;
        }

        @include responsive-for(md) {
          display: block;
          height: 50vh;
        }
      }
    }

    &-address {
      height: 50vh;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      @include responsive-for(md) {
        height: 100%;
        padding: 20px;
      }

      &-box {
        width: 25%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include responsive-for(md) {
          width: 100%;
          text-align: center;
        }

        &-add {
          &-title {
            font-size: large;
            font-family: $robotoMedium;
          }
        }
      }
    }
  }
}
