// COMP WRAPPER STYLES
.compwrapper-container {
  margin-top: 200px;
  // .compwrapper{

    // }

    @include responsive-for(md) {
        margin-top: 170px;
      }
    .compwrapper{
        &-header{
            height: 120px;
            background:$primarycolor;
            &-title{
                padding-top: 50px;
                width: $default_width;
                margin-left: auto;
                margin-right: auto;
                font-size: $heading_one;
                font-family: $robotoBold;
                color: $white;

            }
        }
        &-content{
            padding-top: 60px;
            width: $default_width;
            margin-left: auto;
            margin-right: auto;
            border-radius: 4px;

            @include responsive-for(md) {
                padding-top: 30px;
              }

        }
    }
  }
