.research-study-wrapper {
  .container {
    transition: 0.3s;

    .content-wrapper {
      padding: 20px;

      .title {
        font-family: $robotoBold;
        font-size: $heading_two;
      }

      .study-wrapper {
        padding: 20px 0 0 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include responsive-for(md) {
          grid-template-columns: 1fr 1fr;
        }

        @include responsive-for(sm) {
          grid-template-columns: 1fr;
        }

        .study {
          padding: 20px;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.2s ease-in;

          &-icon {
            width: 40px;
            height: 40px;
            background-color: $primarycolor;
            margin: 10px 0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            transition: background 0.2s, color 0.2s;
          }

          &-image {
            height: 200px;
            border-radius: 4px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          &-title {
            font-family: $robotoMedium;
            padding: 10px 0;
          }

          &-description {
            font-family: $robotoRegular;
          }
        }

        .study:hover {
          background-color: $primarycolor;
          color: $white;

          .study-icon {
            background: $white;
            color: $primarycolor;
          }
        }
      }
    }
  }
}
