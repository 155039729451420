
//NEWSDETAIL STYLES
        //RESEARCHDETAIL STYLES
.news-detail-wrapper {
    .container {
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      // margin-top: 50px;
      padding: 20px;
      overflow: hidden;
      .detail {
        .title {
        //   font-family: $robotoMedium;
          font-size: $heading_two;
                  font-family: $robotoMedium;
        color: $black;
        padding-bottom: 6px;
        margin-bottom: 16px;
        }
        .paragraph {
          font-family: $robotoRegular;
          line-height: 24px;
          padding: 10px 0;
          text-align: justify;
        }
        .image {
          height: 300px;
          width: $default_width/2;
          margin: 10px;
          float: right;
          background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
        }
        @include responsive-for(md) {
          .image {
            float: none;
            width: $default_width;
            margin-top: 20px;
          }
        }
      }
    }
    // .container:hover {
    //   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    // }
  }
  