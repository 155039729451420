.article-container {
  width: 100%;
  @include responsive-for(md) {
    width: 100%;
  }
  .article {
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: 4px;
    padding: 20px;
    @include responsive-for(md) {
      margin-top: 20px;
    }
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      font-family: $robotoMedium;
      color: $black;
      border-bottom: 1px solid $light_grey;
      padding-bottom: 6px;
      margin-bottom: 16px;
      &-title {
        font-size: $heading_two;
      }
    }
    &-body {
      display: grid;
      grid-template-columns: 1fr;
      &-content {
        width: 100%;
        height: 100px;
        display: grid;
        grid-template-columns: 1fr 2fr;

        .image-container {
          height: 100%;
          background-position: center top;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 4px;

          // width: 100px;
          // height: 85px;
          // border-radius: 4px;
          // overflow: hidden;

          // @include responsive-for(lg) {
          //   width: 85px;
          //   height: 85px;
          // }

          // @include responsive-for(md) {
          //   width: 100px;
          //   height: 85px;
          // }

          // .image {
          //   display: flex;
          //   flex: 1;
          //   width: 100px;
          //   height: 85px;
          //   object-fit: cover;

          //   @include responsive-for(lg) {
          //     width: 85px;
          //     height: 85px;
          //   }

          //   @include responsive-for(md) {
          //     width: 100px;
          //     height: 85px;
          //   }
          // }
        }

        &-desc {
          flex-shrink: 10;
          margin-left: 20px;
          position: relative;

          &-title {
            font-family: $robotoMedium;
            display: -moz-box;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -moz-line-clamp: 1;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            overflow: hidden;
          }

          &-body {
            margin-top: 6px;
            display: -moz-box;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            overflow: hidden;
            font-size: $small;

            // margin-bottom: 10px;
          }

          &-footer {
            position: absolute;
            bottom: 0px;
            cursor: pointer;
            // display: flex;
            // align-content: center;
            // font-size: $text;
            // font-family: $robotoMedium;
            // color: $primarycolor;

            // &-icon {
            //   margin-top: 4px;
            //   margin-left: 4px;
            // }
          }
        }

        &:not(:last-child) {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid $extralight_grey;
        }
      }
    }
  }
}
