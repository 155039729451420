.date-container {
  display: flex;
  flex-direction: row;

  span {
    position: relative;
    top: 1px;
  }

  .date {
    margin-left: 5px;
  }
}
