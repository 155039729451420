.ourteam-wrapper {
  .container {
    .teams {
      .cards-container {
        display: grid;
        // grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 30px;

        // @include responsive-for(md) {
        //   // grid-template-columns: repeat(2, 1fr);
        //   grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
        // }

        // @include responsive-for(sm) {
        //   grid-template-columns: repeat(1, 1fr);
        // }

        .cards {
          cursor: pointer;
          background: $white;
          padding: 20px;
          border-radius: 4px;
          transition: all 0.3s;
          height: 100%;

          &:hover {
            transform: scale(1.02);
          }

          .image {
            height: 240px;
            border-radius: 4px;
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
          }

          .information {
            padding: 24px 0 0 0;

            .name {
              font-family: $robotoMedium;
            }

            .designation {
              font-family: $robotoRegular;
              font-size: 14px;
              padding: 5px 0;
              color: $medium_black;
            }

            .address {
              font-family: $robotoRegular;
              font-size: 14px;
              padding: 0 0 5px 0;
              color: $medium_black;
              padding-bottom: 6px;
            }

            .bio {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $light_black;
              font-family: $robotoRegular;
              font-size: 14px;
            }

            .work {
              border-top: 1px solid #f1f1f1;
              margin-top: 10px;
              padding-top: 10px;
              font-family: $robotoMedium;
              color: $black;
            }
          }
        }
      }

      &:not(:first-child) {
        padding-top: 60px;
      }
    }
  }
}
