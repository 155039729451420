// ACTIVE INDEX STYLES
.activeindex {
  display: flex;

  &-dot {
    width: 9px;
    height: 6px;
    border-radius: 2px;
    background: $light_grey;
    margin: 0px 4px;
    transition: width 0.2s;
    cursor: pointer;
    user-select: none;

    &.active {
      width: 22px;
      background: $white;
    }
  }
}
