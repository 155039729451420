.topcard-container {
    margin-top: 60px;
    .topcard{
        width: $default_width;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        // flex-direction: row;
        // justify-content: space-between;
grid-template-columns: 2fr 1fr;
column-gap: 20px;
        @include responsive-for(md) {
            flex-direction: column;
            grid-template-columns: 1fr;
          }

    }
}