.recentevents-container{
    // margin-top: 60px;
    .recentevents{
        display: grid;
        grid-template-columns: 3fr 2fr;
        column-gap: 20px;
        row-gap: 20px;

        @include responsive-for(md) {
            grid-template-columns: 1fr;
          }

        .leftimage{
            height: 100%;
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 4px;
            position: relative;

            @include responsive-for(md) {
                height: 300px;
              }

            &-overlay{
                position: absolute;
                left: 0;
                bottom: 30px;
                background-color: #000000aa;
                min-width:50%;
                max-width:60%;
                padding: 20px;
                display: flex;
                flex-direction: column;

                @include responsive-for(sm) {
                    max-width:80%;
                  }
                &-title{
                    font-size: $text;
                    font-family: $robotoMedium;
                    color: $white;
                    
                    display: -moz-box;
                    display: -webkit-box;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -moz-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    overflow: hidden;
                }

                &-content{
                    margin: 10px 0;
                    font-size: $small;
                    font-family: $robotoRegular;
                    color: $white;

                    display: -moz-box;
                    display: -webkit-box;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -moz-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    overflow: hidden;
                }

                &-footer{
                    color: $white;
                    font-size: $small;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    &-seemorecontainer{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        user-select: none;

                        .seemore {
                            font-family: $robotoMedium;
                            margin-right: 4px;
                            color: $white;
                        
                            &-icon {
                              position: relative;
                              top: 2px;
                              color: $white;
                            }
                          }
                    }
                    
                }
            }
        }
        .rightimage{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 20px;   
            
            &-card{
                width: 100%;
                height: 110px;
                display: grid;
                grid-template-columns: 3fr 5fr;
                column-gap: 20px;

                @include responsive-for(md) {
                    grid-template-columns: 1fr 2fr;
                  }
                .image{
                    height: 100%;
                    background-position: center top;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 4px;
                }
                .body{

                        display: flex;
                        flex-direction: column;
                        flex-shrink: 10;
                        &-title{
                            font-size: $text;
                            font-family: $robotoMedium;
                            color: $black;
                            
                            display: -moz-box;
                            display: -webkit-box;
                            line-clamp: 2;
                            -webkit-line-clamp: 2;
                            -moz-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            -moz-box-orient: vertical;
                            overflow: hidden;
                        }
                    
                        &-content{
                            margin: 10px 0;
                            font-size: $small;
                            font-family: $robotoRegular;
                            color: $light_black;
                    
                            display: -moz-box;
                            display: -webkit-box;
                            line-clamp: 2;
                            -webkit-line-clamp: 2;
                            -moz-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            -moz-box-orient: vertical;
                            overflow: hidden;
                        }
                    
                        &-footer{
                            color: $light_black;
                            font-size: $small;
                            font-family: $robotoMedium;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            &-seemore{
                                color: $primarycolor;
                            }
                        }
                
                }
                &:not(:last-child) >.body {
                    padding-bottom: 10px;
                    border-bottom: 1px solid $grey;
                }
            }
        }
    }
}