.study {
  margin-bottom: 15px;
  .title {
    font-family: $robotoMedium;
    font-size: 20px;
  }
  .contents {
    // padding: 20px 0;

    &-cards {
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      @include responsive-for(md) {
        grid-template-columns: 1fr;
      }
    }
  }
}
