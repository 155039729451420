// HEADER STYLE
.header-container {
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 20px;
  position: fixed;
  background-image: linear-gradient(to bottom right, $primarycolor, #039aa5);
  z-index: 100;
  border-bottom: 4px solid $white;

  @include responsive-for(md) {
    padding-top: 10px;
  }

  .header-wrapper {
    width: $default_width;
    margin: 0px auto;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;

      @include responsive-for(md) {
        display: none;
      }

      .research-program {
        color: $black;
        font-size: 14px;

        font-family: $robotoMedium;
        padding: 6px 12px;
        border-radius: 4px;
        background-color: $extralight_grey;
      }

      .contact {
        display: flex;

        &-info {
          display: flex;
          align-items: center;
          color: $white;
          margin-left: 30px;

          &-icon {
            position: relative;
            top: 2px;
          }

          &-text {
            padding-left: 6px;
            font-family: $robotoMedium;
          }
        }
      }
    }

    .navigation {
      display: flex;
      padding-top: 10px;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        img {
          width: 150px;
          border-radius: 4px;

          @include responsive-for(md) {
            width: 100px;
          }
        }
        .research-program {
          color: $black;
          font-size: 14px;
          font-family: $robotoMedium;
          padding: 6px 12px;
          border-radius: 4px;
          background-color: $extralight_grey;
        }
      }

      .navs {
        position: relative;
        display: flex;
        align-items: center;
        // margin-top: -10px;

        &-dropdown {
          position: absolute;
          left: 30px;
          top: 100%;
          transform: translateY(-50%);
          display: flex;

          @include responsive-for(md) {
            display: none;
          }
        }

        & > ul > li {
          text-wrap: nowrap;
        }

        ul {
          list-style-type: none;
          display: flex;

          li {
            font-family: $robotoMedium;
            color: $white;
            padding: 0px 20px;
            margin-left: 10px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            &:last-child {
              padding-right: 0px;
            }
          }

          @include responsive-for(md) {
            display: none;
          }
        }

        &-menu {
          display: none;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          color: $white;
          cursor: pointer;
          transition: background 0.2s;

          &:hover {
            background: $primarycolor;
          }

          &:active {
            background: darken($primarycolor, 4%);
          }

          @include responsive-for(md) {
            display: flex;
          }
        }

        &-lang {
          margin-right: 20px;
          display: none;

          @include responsive-for(md) {
            display: block;
          }
        }
      }
    }

    .mobile-research-program-container {
      display: none;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: 425px) {
        display: flex;
      }
    }

    .heading {
      &-title {
        padding-top: 20px;
        font-size: $heading_one;
        font-family: $robotoBold;
        color: $white;
      }
    }
  }
}

// LANGUGAGE SELECT
.selectlanguage {
  background: $white;
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid $primarycolor;
  border-radius: 4px;
  color: $black;
  cursor: pointer;
  user-select: none;

  &-flag {
    width: 10px;
    height: 10px;
    background: $grey;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
  }

  &-alias {
    font-family: $robotoMedium;
    margin-left: 4px;
  }

  &-down {
    margin-left: 4px;
  }
}

// MOBILE DRAWER
.mobiledrawer-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 100;
  display: none;
  overflow: auto;

  @include responsive-for(md) {
    display: block;
  }

  .mobiledrawer {
    &-item {
      width: $default_width;
      background: $extralight_grey;
      margin: 0px auto;
      list-style: none;
      border-radius: 4px;
      font-family: $robotoMedium;
      color: $black;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;
    }

    &-dropdown {
      display: flex;
      gap: 8px;
      width: $default_width;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    &-close {
      display: flex;
      justify-content: flex-end;
      width: $default_width;
      margin: 20px auto;

      .close-button {
        display: flex;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        color: $white;
        cursor: pointer;
        transition: background 0.2s;
        background: $primarycolor;

        &:hover {
          background: #05738e;
        }

        &:active {
          background: $primarycolor_dark;
        }
      }
    }
  }

  .mobiledropdown {
    padding: 20px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span:nth-child(1) {
        font-family: $robotoMedium;
      }

      .icon-down {
        position: relative;
        top: 2px;
        transition: transform 0.2s;
      }
    }

    &-list {
      list-style-type: none;
      margin-top: 10px;
      padding-bottom: 5px;

      &-item {
        background: $white;
        margin-bottom: 4px;
        border-radius: 4px;
        color: $black;
        display: flex;
        align-items: center;
        padding: 14px;

        .list-item-icon {
          color: $black;
          background: $extralight_grey;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        .list-item-title {
          padding-left: 10px;
        }

        &.researchProgram {
          font-size: $small;

          &.active {
            background: darken($extralight_grey, 10%);
          }
        }
      }
    }
  }
}
