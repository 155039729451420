.disclaimer-container {
  background-image: linear-gradient(to bottom right, $primarycolor, #039aa5);
  padding: 60px 7.5%;

  h3 {
    font-size: $heading_two;
    font-family: $robotoMedium;
    color: $white;
    margin-bottom: 10px;
  }

  p {
    color: $white;
    line-height: 24px;
  }
}
