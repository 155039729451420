//CONTACTUS STYLES
.contact-page-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @include responsive-for(md) {
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }
}
