.contact-information-wrapper {
  .contact-information-container {
    .title {
      font-size: $heading_two;
      font-family: $robotoBold;
      color: $black;
      padding: 0 0 60px 0;
      line-height: 24px;

      @include responsive-for(md) {
        padding: 0 0 20px 0;
      }
    }
    .information {
      display: flex;

      .info-title {
        padding-left: 20px;
        padding-bottom: 20px;
        // width: 60%;
        line-height: 24px;
      }
    }
  }
}
